<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1><span class="rollback" @click="rollback"><i
            class="fas fa-arrow-circle-left"></i></span>{{ $t("experiences.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
                      :placeholder="$t('Search.search')"
                      v-model="searchItem" @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("experiences.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listExperiences.length>0">
          <thead>
          <tr>
            <th>{{ $t("experiences.no") }}</th>
            <th class="text-left">
              {{ $t("experiences.name") }}
            </th>
            <th class="text-left">
              {{ $t("experiences.job") }}
            </th>
            <th class="text-left">
              {{ $t("experiences.description") }}
            </th>
            <th class="text-left">
              {{ $t("experiences.startDate") }}
            </th>
            <th class="text-left">
              {{ $t("experiences.endDate") }}
            </th>
            <th class="text-left">

            </th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,idx) in listExperiences" :key="idx">
            <td>{{ (pagination.current_page * 10) - 10 + idx + 1 }}</td>
            <td>
              {{ item.company }}
            </td>
            <td>
              {{ item.job }}
            </td>
            <td>
              {{ item.description }}
            </td>
            <td>
              {{ item.start }}
            </td>

            <td>
              {{ item.end }}
            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition"
                      bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                  >
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>

        <defaultTableNoResult v-else/>

        <Pagination
            class="mt-5 mb-5"
            v-if="pagination.total_pages > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchEmployeeExperiences"
        >
        </Pagination>
        <ModalEdit fullscreen title="Edit Experiences">
          <template v-slot="{close}">
            <editExperiences :experiences="experiences" :employee_id="employee_id" @close="close"
                             @success="fetchEmployeeExperiences"/>
          </template>
        </ModalEdit>
        <ModalDelete>
          <template v-slot="{close}">
            <deleteExperiences :experiencesId="experiencesId" :employee_id="employee_id" @close="close"
                               @success="fetchEmployeeExperiences"/>
          </template>
        </ModalDelete>

      </div>
      <Loading v-if="isLoading"/>
    </div>
  </div>
</template>

<script>
import editExperiences from "../../../components/experiences/editExperiences";
import deleteExperiences from "../../../components/experiences/deleteExperiences";
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "../../../components/defaultTableNoResult";

export default {
  components: {
    editExperiences,
    deleteExperiences,
    Loading,
    Pagination,
    defaultTableNoResult
  },
  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,

      isLoading: true,
      listExperiences: [],
      experiences: {},
      experiencesId: "",
      employee_id: this.$route.params.employee_id,
    }
  },
  methods: {
    rollback() {
      this.$router.push({
        name: "manageResume.index"
      })
    },
    searchFilterItem() {
      this.fetchEmployeeExperiences();
    },
    filterExperiences(experiencesId) {
      return (
          this.listExperiences.filter((item) => {
            return item.id == experiencesId;
          })[0] || {}
      )
    },

    onCreate() {
      this.$router.push({
        name: 'experiences.create',
        params: {
          employee_id: this.employee_id
        }
      }).catch(() => {
      });
    },

    onEdit(experiencesId) {
      this.experiences = {
        ...this.filterExperiences(experiencesId)
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(experiencesId) {
      this.experiencesId = experiencesId
      this.$store.commit("modalDelete_State", true);
    },


    fetchEmployeeExperiences() {
      this.$axios.get(`company/experience`, {
        params: {
          page: this.pagination.current_page,
          per_page: this.per_page,
          filter: this.searchItem,
          employee_id: this.employee_id
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.isLoading = false;
          this.listExperiences = res.data.data.data;
          this.pagination = res.data.data.pagination;
          if (!this.listExperiences.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
      }).catch(() => {
      })
    }
  },
  created() {
    this.fetchEmployeeExperiences();
  }
}
</script>

<style scoped lang="scss">

</style>
