<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  outlined
                  dense
                  :label="$t('experiences.job')"
                  v-model="experiences.job"
                  :hint="`${server_errors.job}`"
                  persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                  outlined
                  dense
                  :label="$t('experiences.name')"
                  v-model="experiences.company"
                  :hint="`${server_errors.company}`"
                  persistent-hint
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="12">
              <v-textarea
                  outlined
                  dense
                  :label="$t('experiences.description')"
                  rows="3"
                  row-height="15"
                  v-model="experiences.description"
                  :hint="`${server_errors.description}`"
                  persistent-hint
              >
              </v-textarea>
            </v-col>

            <v-col cols="6" md="6">
              <label class="text-date-label label-input">{{ $t("experiences.startDate") }}<span
                  class="text-show-error">*{{ server_errors.start }}</span></label>
              <DatePicker class="date-time"
                          style="width: 100%;"
                          type="date"
                          valueType="format"
                          slot="activator"
                          :append-to-body="true"
                          v-model="experiences.start"
              ></DatePicker>
            </v-col>
            <v-col cols="6" md="6">
              <label class="text-date-label label-input">{{ $t("experiences.endDate") }}<span
                  class="text-show-error">*{{ server_errors.end }}</span></label>
              <DatePicker class="date-time"
                          style="width: 100%;"
                          type="date"
                          valueType="format"
                          slot="activator"
                          :append-to-body="true"
                          name="date_of_birth"
                          v-model="experiences.end"
                          :hint="`${server_errors.end}`"
                          persistent-hint

              ></DatePicker>
            </v-col>

            <v-col cols="8" class="mt-5">
              <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
                {{ $t("experiences.update") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  props: {
    experiences: {},
    employee_id: {}
  },
  components: {
    DatePicker
  },
  data() {
    return {
      btnLoading: false,
      data: {
        job: "",
        company: "",
        description: "",
        start: "",
        end: "",
      },

      server_errors: {
        job: "",
        company: "",
        description: "",
        start: "",
        end: ""
      }
    }
  },
  methods: {
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true
      }
    },

    saveChange() {
      this.$axios.put(`company/experience/${this.experiences.id}`, this.experiences, {
        params: {
          employee_id: this.employee_id
        }
      }).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('close')
            this.$emit('success')
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.btnLoading = false;
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })

    },

  },
  created() {
  }
}
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 600px;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 100px;
  }

}

.text-date-label {
  color: #6e6d6d;
  font-weight: normal;

  .text-show-error {
    font-size: 12px;
    color: red;
  }
}
</style>
